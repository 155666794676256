// Variables
@import "../variables";

// Colors
$primary-color: #ed9c25;
$black-color: #001216;
$white-color: #f0fcff;

// Rest
@import "../frontend";
